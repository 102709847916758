import * as React from 'react';
import PropTypes from 'prop-types';

import POSScreen from '../../images/odoo-pos';

const POS = ({ bgcolor }) => (
  <div className={bgcolor}>
    <div className="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
      <div>
        <h2 className="text-base font-semibold leading-6 tracking-wide text-indigo-600 uppercase">
          Take payment on the spot
        </h2>
        <p className="mt-2 text-3xl font-extrabold leading-9 text-gray-900">
          Point of Sale
        </p>
        <p className="my-4 text-lg leading-7 text-gray-500">
          Set-up in minutes, sell in seconds. Compatible with any device.
        </p>
        <POSScreen />
      </div>
      <div className="mt-12 lg:mt-0 lg:col-span-2">
        <dl className="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-3 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
          <div className="flex space-x-3">
            {/* Heroicon name: check */}
            <svg
              className="flex-shrink-0 w-6 h-6 text-green-500"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
            <div className="space-y-2">
              <dt className="text-lg font-medium leading-6 text-gray-900">
                Compatible with any hardware
              </dt>
              <dd className="flex space-x-3 lg:py-0 lg:pb-4">
                <span className="text-base leading-6 text-gray-500">
                  No installation and no specific hardware required
                </span>
              </dd>
            </div>
          </div>
          <div className="flex space-x-3">
            {/* Heroicon name: check */}
            <svg
              className="flex-shrink-0 w-6 h-6 text-green-500"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
            <div className="space-y-2">
              <dt className="text-lg font-medium leading-6 text-gray-900">
                Everything your shop needs
              </dt>
              <dd className="flex space-x-3">
                <span className="text-base leading-6 text-gray-500">
                  A smart interface that any retail company can use without
                  difficulty. Can easily be configured to meet your precise
                  needs.
                </span>
              </dd>
            </div>
          </div>
          <div className="flex space-x-3">
            {/* Heroicon name: check */}
            <svg
              className="flex-shrink-0 w-6 h-6 text-green-500"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
            <div className="space-y-2">
              <dt className="text-lg font-medium leading-6 text-gray-900">
                Online or offline
              </dt>
              <dd className="flex space-x-3">
                <span className="text-base leading-6 text-gray-500">
                  Odoo&apos;s Point of Sale stays reliable even if your
                  connection is not. Stays operational even when connection is
                  lost.
                </span>
              </dd>
            </div>
          </div>
          <div className="flex space-x-3">
            {/* Heroicon name: check */}
            <svg
              className="flex-shrink-0 w-6 h-6 text-green-500"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
            <div className="space-y-2">
              <dt className="text-lg font-medium leading-6 text-gray-900">
                Integrated Inventory Management
              </dt>
              <dd className="flex space-x-3">
                <span className="text-base leading-6 text-gray-500">
                  Real time control & accurate forecasts to manage procurements.
                </span>
              </dd>
            </div>
          </div>
          <div className="flex space-x-3">
            {/* Heroicon name: check */}
            <svg
              className="flex-shrink-0 w-6 h-6 text-green-500"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
            <div className="space-y-2">
              <dt className="text-lg font-medium leading-6 text-gray-900">
                Loyalty programs
              </dt>
              <dd className="flex space-x-3">
                <span className="text-base leading-6 text-gray-500">
                  Offer loyalty cards and rewards, control sales and cash in
                  real time, record clients to inform them, etc.
                </span>
              </dd>
            </div>
          </div>
          <div className="flex space-x-3">
            {/* Heroicon name: check */}
            <svg
              className="flex-shrink-0 w-6 h-6 text-green-500"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
            <div className="space-y-2">
              <dt className="text-lg font-medium leading-6 text-gray-900">
                Keep in touch
              </dt>
              <dd className="flex space-x-3 lg:border-t-0 lg:py-0 lg:pb-4">
                <span className="text-base leading-6 text-gray-500">
                  Register your customers to keep track of their buying habits.
                  You can then send them special offers and sale notices with{' '}
                  <strong>Odoo Mailing</strong> .
                </span>
              </dd>
            </div>
          </div>
        </dl>
      </div>
    </div>
  </div>
);

POS.propTypes = {
  bgcolor: PropTypes.string,
};

POS.defaultProps = {
  bgcolor: 'bg-white',
};

export default POS;
